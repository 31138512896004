import { FC } from 'react';
import Button, { ButtonBaseProps } from './ButtonBase';

export type PotProps = ButtonBaseProps;

const Pot: FC<PotProps> = ({ ...props }) => {
    return <Button label="pot" {...props} />;
};

export default Pot;
