import styled from 'styled-components';
import AllIn from './Buttons/AllIn';
import Slider from './Slider';
import Potx2 from './Buttons/Potx2';
import HalfPot from './Buttons/HalfPot';
import Pot from './Buttons/Pot';
import Bet from './Buttons/Bet';
import Call from './Buttons/Call';
import Fold from './Buttons/Fold';
import {
    useGameContext,
    GamePhase,
    usePlayerContext,
} from 'context/GameContext';
import { FC, useEffect, useState } from 'react';
import NoSleep from 'nosleep.js';
import { Color } from './Buttons/ButtonBase';

const Row = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
`;

const ControlsContainer = styled.div`
    width: 100%;
    margin-top: 10px;
`;

const noSleep = new NoSleep();

const Controls: FC = () => {
    const { G, ctx, moves } = useGameContext();
    const { isTurn, player } = usePlayerContext();
    const { minRaise, table } = G;
    const { pot } = table;
    const buttonsActive = isTurn && ctx.phase === GamePhase.Hand;
    const { amountToCall, stack } = player;
    const callIsAllIn = amountToCall >= stack;
    const [defaultBet, setDefaultBet] = useState(0);
    const [bet, setBet] = useState(defaultBet);

    const onFold = () => {
        noSleep.enable();
        betMode ? setBetMode(false) : moves.fold();
    };

    const onCall = () => {
        noSleep.enable();
        moves.call();
    };

    const onRaise = () => {
        noSleep.enable();
        if (betMode) {
            moves.raise(bet);
            setBetMode(false);
        } else {
            setBetMode(true);
            setBet(defaultBet);
        }
    };

    const minValue = Math.min(amountToCall + minRaise, stack);
    const [betMode, setBetMode] = useState(false);
    const potTimesTwo = pot * 2;
    const canBetPotTimesTwo = stack >= potTimesTwo && minValue <= potTimesTwo;
    const halfPot = Math.floor(pot / 2);
    const canBetHalfPot = stack >= halfPot && minValue <= halfPot;
    const canBetPot = stack >= pot && minValue <= pot;

    const updateValue = (value: string | number) => {
        const valueToSet = Number(value);
        setBet(valueToSet);
    };

    const {
        label: betLabel,
        color: betColor,
    }: { label: string; color: Color } =
        bet === stack
            ? { label: `All In !! $${bet}`, color: 'blue' }
            : amountToCall === 0 && betMode
            ? { label: `Bet ! $${bet}`, color: 'yellow' }
            : amountToCall === 0
            ? { label: `Place Bet`, color: 'yellow' }
            : betMode
            ? { label: `Raise ! $${bet}`, color: 'yellow' }
            : { label: `Raise !`, color: 'yellow' };

    const foldString = betMode ? 'Cancel Bet' : 'Fold';

    const callString =
        amountToCall === 0
            ? 'Check'
            : callIsAllIn
            ? `All In !! $${amountToCall}`
            : `Call -> $${amountToCall}`;
    const callColor =
        amountToCall === 0 ? 'green' : callIsAllIn ? 'blue' : 'green';

    const sliderMinPosition = minValue === stack ? 0 : defaultBet;

    useEffect(() => {
        setDefaultBet(Math.min(amountToCall + minRaise, stack));
    }, [minRaise, amountToCall, stack, defaultBet]);

    return (
        <ControlsContainer>
            <Row>
                <HalfPot
                    disabled={!(canBetHalfPot && buttonsActive && betMode)}
                    onClick={() => updateValue(halfPot)}
                    css={`
                        flex-basis: 22%;
                    `}
                />
                <Pot
                    disabled={!(canBetPot && buttonsActive && betMode)}
                    onClick={() => updateValue(pot)}
                    css={`
                        flex-basis: 22%;
                    `}
                />
                <Potx2
                    disabled={!(canBetPotTimesTwo && buttonsActive && betMode)}
                    onClick={() => updateValue(potTimesTwo)}
                    css={`
                        flex-basis: 22%;
                    `}
                />
                <AllIn
                    disabled={!(buttonsActive && betMode)}
                    onClick={() => updateValue(stack)}
                    css={`
                        flex-basis: 22%;
                    `}
                />
            </Row>
            <Row
                css={`
                    margin-top: 40px;
                    margin-bottom: 20px;
                `}
            >
                <Slider
                    disabled={!(buttonsActive && betMode)}
                    step={G.SMALL_BLIND_VALUE}
                    value={bet}
                    min={sliderMinPosition}
                    max={stack}
                    setValue={setBet}
                    css={`
                        flex-grow: 1;
                        margin-bottom: 10px;
                    `}
                />
            </Row>
            <Row>
                <Fold
                    label={foldString}
                    onClick={onFold}
                    disabled={!buttonsActive}
                    css={`
                        flex-basis: 30%;
                        flex-shrink: 0;
                    `}
                />
                <Call
                    label={callString}
                    color={callColor}
                    disabled={!buttonsActive || betMode}
                    onClick={onCall}
                    css={`
                        flex-basis: 30%;
                        flex-shrink: 0;
                    `}
                />
                <Bet
                    css={`
                        flex-basis: 30%;
                        flex-shrink: 0;
                    `}
                    label={betLabel}
                    color={betColor}
                    disabled={!buttonsActive || callIsAllIn}
                    onClick={onRaise}
                />
            </Row>
        </ControlsContainer>
    );
};

export default Controls;
