import React from 'react';
import styled from 'styled-components';

const SliderThumbContainer = styled.div`
    z-index: 3;
    position: absolute;
    background: #d0d0d0;
    border: 1px solid #ffffff;
    width: 10px;
    height: 60%;
    bottom: 0;
    top: 0;
    margin: auto;
`;

const SliderThumbThumbnail = styled.div<{ disabled?: boolean }>`
    position: absolute;
    bottom: 180%;
    color: white;
    background: #2a312f;
    border: 2px solid white;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    transform: translate3d(-50%, 0, 0);
    visibility: ${(p) => (p.disabled ? 'hidden' : 'visible')};
`;

const SliderThumb: React.ElementType<any> = React.forwardRef(
    ({ children, amount, ...props }, ref: React.Ref<HTMLDivElement>) => {
        return (
            <SliderThumbContainer {...props} ref={ref}>
                <SliderThumbThumbnail
                    disabled={props.ownerState.disabled}
                    {...props}
                >
                    ${props.ownerState.value}
                </SliderThumbThumbnail>
                {children}
            </SliderThumbContainer>
        );
    }
);

export default SliderThumb;
