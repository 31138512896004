import { useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Card } from '@magicyard/poker-components';
import GameContext, { getPlayer } from '../../context/GameContext';
import NoSleep from 'nosleep.js';

const Container = styled.div<{ peek?: boolean }>`
    position: relative;
    height: 40vh;
    width: 100%;
    margin: 30px 0;
    display: flex;
    justify-content: center;

    ${Card} {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: auto;
        height: 100%;
        transform-origin: top left;

        &:nth-of-type(1),
        &:nth-of-type(3) {
            transform: rotate(8deg);
            z-index: 1;
            transform-origin: top right;
            top: 20px;
            left: 110px;
        }

        &:nth-of-type(2),
        &:nth-of-type(4) {
            top: -3px;
            left: -95px;
            transform: rotate(-7deg);
        }

        &:nth-of-type(1),
        &:nth-of-type(2) {
            visibility: ${(props) => (props.peek ? 'hidden' : 'visible')};
            /* border: 1px solid black;
            border-radius: 17px; */
        }

        &:nth-of-type(3),
        &:nth-of-type(4) {
            visibility: ${(props) => (props.peek ? 'visible' : 'hidden')};
            /* border: 1px solid black;
            border-radius: 17px; */
        }
    }
`;

const Backdrop = styled.div`
    z-index: 20;
    position: absolute;
    height: calc(100% + 60px);
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
`;

const noSleep = new NoSleep();

const Hand = () => {
    const { G, playerID } = useContext(GameContext);

    const player = getPlayer(G, +playerID);

    const [isPeeking, setIsPeeking] = useState(false);

    const onPeek = useCallback((ev) => {
        noSleep.enable();
        setIsPeeking(true);
    }, []);
    const onPeekEnd = useCallback(() => {
        setIsPeeking(false);
    }, []);

    return player.active ? (
        <Container peek={isPeeking}>
            <Backdrop onTouchStart={onPeek} onTouchEnd={onPeekEnd} />
            <Card shadow={true} fileName="RED_BACK" />
            <Card shadow={true} fileName="RED_BACK" />
            <Card shadow={true} fileName={player.cards[0].fileName} />
            <Card shadow={true} fileName={player.cards[1].fileName} />
        </Container>
    ) : null;
};

export default Hand;
