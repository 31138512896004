import React from 'react';
import { LongPressCallback } from 'use-long-press';

export type SliderContextType = {
    amount: number;
    onPlusTouchStart: LongPressCallback<Element>;
    onPlusTouchEnd: LongPressCallback<Element>;
    onPlusLongPress: LongPressCallback<Element>;
    onMinusTouchStart: LongPressCallback<Element>;
    onMinusTouchEnd: LongPressCallback<Element>;
    onMinusLongPress: LongPressCallback<Element>;
    longPressThresholdMS: number;
    longPressKeyRepeatMS: number;
};

export const SliderContext = React.createContext<SliderContextType>(null);

export const useSliderContext = () => React.useContext(SliderContext);
