import { FC } from 'react';
import Button, { ButtonBaseProps } from './ButtonBase';

export type FoldProps = ButtonBaseProps;

const Fold: FC<FoldProps> = ({ ...props }) => {
    return <Button label="Fold" color="red" {...props} />;
};

export default Fold;
