import { createContext, useContext } from 'react';
import {
    GameObject,
    getPlayer,
    GamePhase,
} from '@magicyard/poker-game/src/Game';

export type GameContextType = GameObject;

const GameContext = createContext<GameContextType>(null);

export const useGameContext = () => useContext(GameContext);

export const usePlayerContext = () => {
    const {
        G,
        ctx: { currentPlayer },
        playerID,
    } = useGameContext();

    return {
        player: getPlayer(G, +playerID),
        isTurn: currentPlayer === playerID,
        playerID,
    };
};

export default GameContext;

export { GamePhase, getPlayer };
