import { FC } from 'react';
import Button, { ButtonBaseProps } from './ButtonBase';

export type Potx2Props = ButtonBaseProps;

const Potx2: FC<Potx2Props> = ({ ...props }) => {
    return <Button label="x2 Pot" {...props} />;
};

export default Potx2;
