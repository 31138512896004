import { Client } from 'boardgame.io/react';
import { Local, SocketIO } from 'boardgame.io/multiplayer';
import Board from './Board';
import { Poker } from '@magicyard/poker-game/src/Game';

const urlParams = new URLSearchParams(window.location.search);
const serverURL =
    urlParams.get('serverURL') || process.env.REACT_APP_SERVER_URL;

const getMultiplayerMode = () => {
    if (process.env.MULTIPLAYER_MODE?.toLowerCase() === 'local') {
        console.log('Using Local() multiplayer');
        return Local();
    }

    console.log(
        `Running using SocketIO() multiplayer with server: ${serverURL}`
    );
    return SocketIO({ server: serverURL });
};

const App = Client({
    game: Poker,
    numPlayers: 6,
    board: Board,
    debug: !window.location.search.includes('hidePanel'),
    multiplayer: getMultiplayerMode(),
});

export default App;
