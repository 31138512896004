import { NewStack } from '@magicyard/poker-components';

export type StackProps = {
    amount: number;
};

const Stack = ({ amount }) => {
    return <NewStack chipURL={'/images/stack-chips.png'} amount={amount} />;
};

export default Stack;
