const font = {
    button: {
        family: 'Lexend Deca',
        size: '18px',
        lineHeight: '20px',
        weight: '500',
        color: '#fff',
    },
    stack: {
        family: 'Lexend Deca',
        size: {
            large: '33px',
            normal: '24px',
        },
        lineHeight: {
            large: '39px',
            normal: '28px',
        },
        weight: {
            normal: '400',
            bold: '700',
        },
        color: '#fff',
    },
    info: {
        family: 'Lexend Deca',
        size: '33px',
        lineHeight: '39px',
        weight: '500',
        color: '#fff',
    },
};

const space = {
    padding: {
        small: '10px',
        large: '15px',
    },
    margin: {
        small: '12px',
    },
};

const theme = {
    font,
    space,
};

export default theme;
