import { FC } from 'react';
import Button, { ButtonBaseProps } from './ButtonBase';

export type BetProps = ButtonBaseProps;

const Bet: FC<BetProps> = ({ ...props }) => {
    return <Button color="yellow" label="Bet" {...props} />;
};

export default Bet;
