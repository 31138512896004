import styled from 'styled-components';

export type LookUpLabelProps = {
    active: boolean;
    className?: string;
};

const LookUpLabelStyle = styled.div<{ active: boolean }>`
    visibility: ${(p) => (p.active ? 'visible' : 'hidden')};
    text-transform: capitalize;
    background: #094963;
    border-radius: 80px;
    box-shadow: 0 0 2px 2px #094963 inset;
    border: 1px solid #00b7ff;
    padding: 10px 30px;
    color: white;
    box-shadow:0px 0px 25px 15px white;
    font-size: 1.7rem;
    align-content: center;
`;

const LookUpLabel = ({ active, className = '' }) => {
    return (
        <LookUpLabelStyle className={className} active={active}>
            Look up!
        </LookUpLabelStyle>
    );
};

export default LookUpLabel;
